<template>
  <div v-if="isPrerender() || !loaded">
    <slot><p>Bezig met laden..</p></slot>
  </div>
  <div v-else v-html="parsedAPITemplate"></div>
</template><script>
export default {
  name: "ApiContent",
  props: {
    contentKey: {
      type: String,
      required: true,
    },
    variables: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loaded: false,
      api_template: "<div></div>",
    };
  },
  methods: {
    isPrerender() {
      return (
        window.__PRERENDER_INJECTED ||
        document.__PRERENDER_INJECTED ||
        window["__PRERENDER_INJECTED"] ||
        document["__PRERENDER_INJECTED"]
      );
    },
  },
  computed: {
    parsedAPITemplate() {
      let parsed = this.api_template;

      let regex = new RegExp("{{\\s([a-z0-9-_]*)\\s+}}", "gi");
      parsed = parsed.replace(regex, (matched, varname, original) => {
        if (this.variables[varname] == undefined) {
          throw "Undefined API Content Variable '" + varname + "'";
        }
        return this.variables[varname];
      });

      return parsed;
    },
  },
  mounted() {
    // load data from leadgen API
    this.axios({
      method: "get",
      url: process.env.VUE_APP_LG_API_BASE + "api/content/" + this.contentKey,
    })
      .then((res) => {
        // set banks
        this.api_template = res.data;
        this.loaded = true;
      })
      .catch((e) => {
        alert("Content not found");
      });
  },
};
</script>

<style>
</style>